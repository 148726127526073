import { TemplateSaveErrors } from '../../../../hooks/data/useTemplates';

function TemplateSaveErrorBanner({
  saveErrors,
}: {
  saveErrors: TemplateSaveErrors;
}) {
  const mapErrorToMessage: {
    [key: string]: string;
  } = {
    emptyName: 'Name is required',
    emptyPartName: 'Part name is required',
    partWithoutRows: 'At least one row is required',
    rowWithoutValue: 'All rows must have a value',
  };

  if (!Object.values(saveErrors).some(error => error)) {
    return null;
  }
  return (
    <div className='w-full rounded-lg border border-red-500 bg-red-100 px-5 py-2 font-semibold text-gray-900'>
      There are unsaved changes! Please fix the following errors before saving:
      <ul className='list-inside list-disc font-normal'>
        {Object.entries(saveErrors).map(([error, hasError]) => {
          if (!hasError) return null;
          return <li key={error}>{mapErrorToMessage[error]}</li>;
        })}
      </ul>
    </div>
  );
}

export default TemplateSaveErrorBanner;
