import { FaQuestion } from 'react-icons/fa';
import { secondsToProjectCardTime } from '../../../../hooks/utils/dateTimeUtils';
import { Project } from '../../../../types/Project';
import { Tooltip } from 'react-tooltip';

type ProjectCardProps = {
  project: Project;
};

function ProjectCard({ project }: ProjectCardProps) {
  const partTotalRows = project.template.parts[project.currentPart].rows.length;
  const totalCompletedRows =
    project.template.parts.reduce(
      (acc, part) =>
        acc +
        part.rows.filter((_, index) => index < project.currentPart).length,
      0
    ) +
    (project.currentRow + 1);

  const totalRows = project.template.parts.reduce(
    (acc, part) => acc + part.rows.length,
    0
  );

  const totalParts = project.template.parts.length;

  return (
    <div className='relative w-full flex-1 rounded-md border border-main-100 p-3 shadow-md hover:bg-main-200 sm:w-60'>
      <Tooltip id={`project-details-${project.id}`}>
        Created at: {new Date(project.createdAt).toLocaleString()}
      </Tooltip>
      <span
        className='absolute right-2 top-2 hidden sm:block'
        data-tooltip-id={`project-details-${project.id}`}
      >
        <FaQuestion className='text-main-500' size={10} />
      </span>
      <p className='truncate pr-2 font-semibold'>{project.name}</p>
      <div>
        <p className='mb-2 mt-1 text-sm text-main-600'>
          Part {project.currentPart + 1} of {totalParts} (Row{' '}
          {project.currentRow + 1} of {partTotalRows})
        </p>
        <div className='flex items-center justify-between text-xs text-main-500'>
          <p>
            {project.done
              ? 'Done'
              : `${Math.floor((totalCompletedRows / totalRows) * 100)} % total rows`}
          </p>
          <p>{secondsToProjectCardTime(project.timeSpent)}</p>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
