import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/shared/Button/Button';
import LoadingFill from '../../components/core/LoadingFIll/LoadingFill';

function Landing() {
  const { loginWithPopup, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  const handleLogin = () => {
    loginWithPopup();
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return (
      <div className='fixed left-0 top-0 flex h-screen w-screen items-center justify-center'>
        <LoadingFill />;
      </div>
    );
  }

  return (
    <div className='flex min-h-screen w-screen flex-col items-center justify-center'>
      <h1 className='text-center text-3xl sm:text-5xl'>
        Welcome to StitchBuddy
      </h1>
      <Button onClick={handleLogin} extraClasses='mt-4'>
        Login
      </Button>
    </div>
  );
}

export default Landing;
