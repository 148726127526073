import { Route, Routes } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import NavBar from './components/core/NavBar/NavBar';
import Landing from './pages/Landing/Landing';
import CreateTemplate from './pages/CreateTemplate/CreateTemplate';
import Dashboard from './pages/Dashboard/Dashboard';
import ProjectDetails from './pages/ProjectDetails/ProjectDetails';
import TemplateAdmin from './pages/TemplateAdmin/TemplateAdmin';
import { useEffect } from 'react';
import useVersion from './hooks/data/useVersion';

function App() {
  const HALF_HOUR = 1800000;
  const FOREVER = 10000000000;

  const { getVersion, version } = useVersion();
  useEffect(() => {
    if (version === '') return;

    console.log('version', version);

    if (version !== APP_VERSION) {
      toast.error('New version available, please refresh the page', {
        position: 'bottom-center',
        icon: '⚡',
        duration: FOREVER,
      });
    }
  }, [version]);

  useEffect(() => {
    setTimeout(() => {
      getVersion();
    }, HALF_HOUR);
  }, []);

  return (
    <div className="bg-main-50 font-main">
      <div>
        <Toaster position="bottom-left" reverseOrder={false} />
      </div>
      <div className="flex min-h-screen w-full flex-col">
        <NavBar />
        <main className="flex w-full flex-1 flex-col">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/templates/create" element={<CreateTemplate />} />
            <Route path="/templates/admin/:id" element={<TemplateAdmin />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
          </Routes>
        </main>
        <p className="w-screen text-center text-xs">
          Made with ❤️ by moisesrj97 - v{APP_VERSION}
        </p>
      </div>
    </div>
  );
}

export default App;
