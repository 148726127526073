import axios from '../../utils/customAxiosClient';
import { useState } from 'react';

export default function useVersion() {
  const [version, setVersion] = useState('');

  const getVersion = async () => {
    const {
      data: { version },
    } = await axios.get(`${import.meta.env.VITE_APP_BACKEND_URL}/version`);

    setVersion(version);
  };

  return { version, getVersion };
}
