import React, { useMemo } from 'react';
import { Template } from '../../../../types/Template';
import { FaCopy, FaEllipsisV, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { TemplateSaveErrors } from '../../../../hooks/data/useTemplates';
import TemplateSaveErrorBanner from '../TemplateSaveErrorBanner/TemplateSaveErrorBanner';
import Button from '../../../../components/shared/Button/Button';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';

function EditPartModal({
  isOpen,
  success,
  partId,
  setTemplate,
  template,
  saveErrors,
}: {
  isOpen: boolean;
  saveErrors: TemplateSaveErrors;
  success: () => void;
  partId: string;
  template: Template;
  setTemplate: (template: Template) => void;
}) {
  const handleAddRow = () => {
    setTemplate({
      ...template,
      parts: template.parts.map(p => {
        if (p.id === partId) {
          return {
            ...p,
            rows: [...p.rows, ''],
          };
        }
        return p;
      }),
    });
  };

  const handleRemoveRow = (index: number) => {
    setTemplate({
      ...template,
      parts: template.parts.map(p => {
        if (p.id === partId) {
          return {
            ...p,
            rows: p.rows.filter((_, i) => i !== index),
          };
        }
        return p;
      }),
    });
  };

  const handleRowChange = async (
    partId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTemplate({
      ...template,
      parts: template.parts.map(p => {
        if (p.id === partId) {
          return {
            ...p,
            rows: p.rows.map((r, i) => {
              if (i.toString() === event.target.name) {
                return event.target.value;
              }
              return r;
            }),
          };
        }
        return p;
      }),
    });
  };

  const handleDuplicateRow = (index: number) => {
    setTemplate({
      ...template,
      parts: template.parts.map(p => {
        if (p.id === partId) {
          return {
            ...p,
            rows: [
              ...p.rows.slice(0, index + 1),
              p.rows[index],
              ...p.rows.slice(index + 1),
            ],
          };
        }
        return p;
      }),
    });
  };

  const handleNameChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTemplate({
      ...template,
      parts: template.parts.map(p => {
        if (p.id === partId) {
          return {
            ...p,
            name: event.target.value,
          };
        }
        return p;
      }),
    });
  };

  const currentPart = useMemo(
    () => template.parts.find(p => p.id === partId),
    [partId, template]
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setTemplate({
      ...template,
      parts: template.parts.map(p => {
        if (p.id === partId) {
          const rows = [...p.rows];
          const [removed] = rows.splice(sourceIndex, 1);
          rows.splice(destinationIndex, 0, removed);
          return {
            ...p,
            rows,
          };
        }
        return p;
      }),
    });
  };

  if (!isOpen) return null;

  return (
    <>
      {currentPart && (
        <Transition appear show={isOpen}>
          <div className='fixed right-0 top-0 flex h-screen w-screen items-center justify-center bg-black/75'>
            <Dialog
              onClose={success}
              as='div'
              className='relative focus:outline-none'
            >
              <div className='fixed inset-0 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-center justify-center p-4'>
                  <TransitionChild
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 transform-[scale(95%)]'
                    enterTo='opacity-100 transform-[scale(100%)]'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 transform-[scale(100%)]'
                    leaveTo='opacity-0 transform-[scale(95%)]'
                  >
                    <DialogPanel className='w-full max-w-md rounded-xl bg-white p-6'>
                      <TemplateSaveErrorBanner saveErrors={saveErrors} />
                      <DialogTitle
                        as='h3'
                        className='my-2 text-2xl font-medium text-black'
                      >
                        Add a new part
                      </DialogTitle>
                      <form className='flex flex-col gap-4'>
                        <label className='flex flex-col'>
                          Name
                          <input
                            type='text'
                            className='rounded-md border border-main-400'
                            value={currentPart.name}
                            onChange={handleNameChange}
                          />
                        </label>
                        <div className='flex items-center gap-2'>
                          <h2 className='text-xl font-semibold'>Rows</h2>
                          <button
                            type='button'
                            className='text-main-600 hover:text-main-800'
                            onClick={handleAddRow}
                          >
                            <IoIosAddCircleOutline size={25} />
                          </button>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='droppable'>
                            {provided => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className='custom-scroll-bar flex flex-col gap-3 overflow-y-auto pr-2'
                              >
                                {currentPart.rows.map((row, rowIndex) => (
                                  <Draggable
                                    key={rowIndex}
                                    draggableId={rowIndex.toString()}
                                    index={rowIndex}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <label
                                          key={rowIndex}
                                          className={
                                            'flex items-center gap-3' +
                                            (snapshot.isDragging
                                              ? ' bg-main-100'
                                              : '')
                                          }
                                        >
                                          {rowIndex + 1}:
                                          <input
                                            type='text'
                                            value={row}
                                            className='w-full rounded-md border border-main-400'
                                            name={rowIndex.toString()}
                                            onChange={e =>
                                              handleRowChange(currentPart.id, e)
                                            }
                                          />
                                          <Menu>
                                            <MenuButton>
                                              <FaEllipsisV />
                                            </MenuButton>
                                            <Transition
                                              enter='transition ease-out duration-75'
                                              enterFrom='opacity-0 scale-95'
                                              enterTo='opacity-100 scale-100'
                                              leave='transition ease-in duration-100'
                                              leaveFrom='opacity-100 scale-100'
                                              leaveTo='opacity-0 scale-95'
                                            >
                                              <MenuItems
                                                anchor='bottom end'
                                                className='flex flex-col items-stretch rounded-md bg-white shadow-sm'
                                              >
                                                <MenuItem>
                                                  <button
                                                    onClick={() =>
                                                      handleDuplicateRow(
                                                        rowIndex
                                                      )
                                                    }
                                                    className='flex items-center justify-center gap-2 rounded-t-md bg-main-100 p-2 hover:bg-main-500 hover:text-white'
                                                  >
                                                    Duplicate <FaCopy />
                                                  </button>
                                                </MenuItem>
                                                <MenuItem>
                                                  <button
                                                    onClick={() =>
                                                      handleRemoveRow(rowIndex)
                                                    }
                                                    className='flex items-center justify-center gap-2 rounded-b-md bg-main-100 p-2 hover:bg-red-500 hover:text-white'
                                                  >
                                                    Delete
                                                    <FaRegTrashAlt />
                                                  </button>
                                                </MenuItem>
                                              </MenuItems>
                                            </Transition>
                                          </Menu>
                                        </label>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {currentPart.rows.length > 0 && (
                          <Button
                            attributes={{
                              type: 'button',
                            }}
                            type='colorless'
                            onClick={handleAddRow}
                            extraClasses='flex flex-gap items-center self-center gap-2'
                          >
                            Add row
                            <FaPlus />
                          </Button>
                        )}
                        <Button
                          onClick={success}
                          attributes={{
                            type: 'button',
                          }}
                        >
                          Close
                        </Button>
                      </form>
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </Dialog>
          </div>
        </Transition>
      )}
    </>
  );
}

export default EditPartModal;
