import { useEffect, useState } from 'react';
import useProjects from '../../../../hooks/data/useProjects';
import { FaPlay, FaStop } from 'react-icons/fa';
import { secondsToStopwatch } from '../../../../hooks/utils/dateTimeUtils';

function ProjectClock({
  projectId,
  startingTime,
}: {
  projectId: string;
  startingTime: number;
}) {
  const [time, setTime] = useState<number>(startingTime);
  const [timerRunning, setTimerRunning] = useState(false);

  const { updateProject } = useProjects();

  const startTimer = () => {
    setTimerRunning(true);
  };

  const stopTimer = () => {
    setTimerRunning(false);
    updateProject(projectId, {
      timeSpent: time,
    });
  };

  useEffect(() => {
    if (!timerRunning) return;

    const interval = setInterval(() => {
      setTime(prevTime => prevTime + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    if (time % 30 === 0) {
      updateProject(projectId, {
        timeSpent: time,
      });
    }
  }, [time]);

  return (
    <div className='flex flex-col items-center gap-2'>
      <p className='text-center text-xl font-semibold'>
        {secondsToStopwatch(time)}
      </p>
      <div className='flex items-center gap-2'>
        <button
          disabled={!timerRunning}
          onClick={stopTimer}
          className='text-main-500 hover:text-main-700 disabled:cursor-not-allowed disabled:opacity-25'
        >
          <FaStop />
        </button>
        <button
          disabled={timerRunning}
          onClick={startTimer}
          className='text-main-500 hover:text-main-700 disabled:cursor-not-allowed disabled:opacity-25'
        >
          <FaPlay />
        </button>
      </div>
    </div>
  );
}

export default ProjectClock;
