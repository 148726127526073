import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProjects from '../../hooks/data/useProjects';
import {
  FaArrowLeft,
  FaArrowRight,
  FaCheck,
  FaEllipsisV,
  FaRegTrashAlt,
} from 'react-icons/fa';
import useProtectedPage from '../../hooks/utils/useProtectedPage';
import LoadingFill from '../../components/core/LoadingFIll/LoadingFill';
import ProjectClock from './components/ProjectClock/ProjectClock';
import DeleteConfirmationModal from '../../components/shared/DeleteConfirmationModal/DeleteConfirmationModal';
import toast from 'react-hot-toast';
import Counters from './components/Counters/Counters';
import {
  Menu,
  MenuButton,
  Transition,
  MenuItems,
  MenuItem,
} from '@headlessui/react';
import PartCompletedPopUp from './components/PartCompletedPopUp/PartCompletedPopUp';

function ProjectDetails() {
  useProtectedPage();

  const { id } = useParams();
  const { projectDetails, setProjectDetails, fetchProject, deleteProject } =
    useProjects();

  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [showCompletedPartPopUp, setShowCompletedPartPopUp] = useState(false);

  useEffect(() => {
    if (!id) return;
    fetchProject(id);
  }, [id]);

  const getCurrentPart = () => {
    if (!projectDetails) return null;
    return projectDetails.template.parts[projectDetails.currentPart];
  };

  const handleNext = async () => {
    if (!projectDetails || !id) return;

    const currentPart = getCurrentPart()!;

    // Move to next row
    if (projectDetails.currentRow < currentPart.rows.length - 1) {
      const payload = { currentRow: projectDetails.currentRow + 1 };
      setProjectDetails({
        ...projectDetails,
        ...payload,
      });
      return;
    }

    // Move to next part
    if (
      projectDetails.currentRow === currentPart.rows.length - 1 &&
      projectDetails.currentPart < projectDetails.template.parts.length
    ) {
      setShowCompletedPartPopUp(true);
      setProjectDetails({
        ...projectDetails,
        currentPart: projectDetails.currentPart + 1,
        currentRow: 0,
      });
      return;
    }
  };

  const handlePrevious = async () => {
    if (!projectDetails || !id) return;

    // Move to previous row
    if (projectDetails.currentRow > 0) {
      setProjectDetails({
        ...projectDetails,
        currentRow: projectDetails.currentRow - 1,
      });
      return;
    }

    // Move to previous part
    if (projectDetails.currentRow === 0 && projectDetails.currentPart > 0) {
      setProjectDetails({
        ...projectDetails,
        currentPart: projectDetails.currentPart - 1,
        currentRow:
          projectDetails.template.parts[projectDetails.currentPart - 1].rows
            .length - 1,
      });
      return;
    }
  };

  const handleDeleteProject = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDeleteProject = async () => {
    if (!id) return;
    await toast.promise(deleteProject(id), {
      error: 'Failed to delete project',
      loading: 'Deleting project...',
      success: 'Project deleted successfully',
    });
    setIsDeleteModalOpen(false);
    navigate('/dashboard');
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!id || !projectDetails) return;
    setProjectDetails({ ...projectDetails, name: e.target.value });
  };

  const shouldShowPrevious = () => {
    if (!projectDetails) return false;

    return projectDetails.currentPart > 0 || projectDetails.currentRow > 0;
  };

  const shouldShowNext = () => {
    if (!projectDetails) return false;

    const currentPart = getCurrentPart()!;

    return (
      projectDetails.currentPart < projectDetails.template.parts.length - 1 ||
      projectDetails.currentRow < currentPart.rows.length - 1
    );
  };

  if (!id || !projectDetails)
    return (
      <div className='fixed left-0 top-0 flex h-screen w-screen items-center justify-center'>
        <LoadingFill />
      </div>
    );

  return (
    <>
      <PartCompletedPopUp
        isOpen={showCompletedPartPopUp}
        setIsOpen={setShowCompletedPartPopUp}
      />
      <DeleteConfirmationModal
        description='Are you sure you want to delete this project?'
        details='This action cannot be undone.'
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirmation={handleConfirmDeleteProject}
        title='Delete project'
      />
      <div className='flex flex-1 flex-col items-center px-4'>
        <div className='flex w-full max-w-screen-md flex-1 flex-col items-stretch'>
          <div className='mb-2 mt-8 flex w-full items-center justify-between'>
            <label className='flex w-full items-center gap-2 sm:w-fit'>
              <span className='font-semibold'>Name:</span>
              <input
                type='text'
                className='w-full rounded-md border border-main-400'
                value={projectDetails?.name}
                onChange={handleChangeName}
              />
            </label>
            <Menu>
              <MenuButton>
                <FaEllipsisV />
              </MenuButton>
              <Transition
                enter='transition ease-out duration-75'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <MenuItems
                  anchor='bottom end'
                  className='flex flex-col items-stretch rounded-md bg-white shadow-sm'
                >
                  <MenuItem>
                    <button
                      onClick={() =>
                        setProjectDetails({
                          ...projectDetails,
                          done: !projectDetails.done,
                        })
                      }
                      className='flex items-center justify-center gap-2 rounded-t-md bg-main-100 p-2 hover:bg-main-500 hover:text-white'
                    >
                      {projectDetails.done ? 'Not done' : 'Done'} <FaCheck />
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button
                      onClick={handleDeleteProject}
                      className='flex items-center justify-center gap-2 rounded-b-md bg-main-100 p-2 hover:bg-red-500 hover:text-white'
                    >
                      Delete
                      <FaRegTrashAlt />
                    </button>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
          <div className='flex w-full flex-col sm:flex-row sm:items-center sm:justify-center sm:gap-10'>
            <div className='flex grow-[2] flex-col justify-center sm:flex-initial'>
              <div className='mt-12 flex items-center justify-center gap-5 text-lg font-semibold'>
                {getCurrentPart() && (
                  <p>
                    Rows: {projectDetails.currentRow + 1}/
                    {getCurrentPart()?.rows.length}
                  </p>
                )}
                <p>
                  Part: {projectDetails.currentPart + 1}/
                  {projectDetails.template.parts.length} (
                  {getCurrentPart()?.name || 'None'})
                </p>
              </div>
              <div className='mb-8 flex w-full flex-col items-center'>
                {getCurrentPart() && (
                  <p className='my-4 text-4xl font-bold'>
                    {getCurrentPart()!.rows[projectDetails.currentRow]}
                  </p>
                )}
                <div className='mb-8 mt-2 flex items-center gap-3'>
                  <button
                    className='flex items-center gap-2 rounded-full border-2 border-main-600 p-2 text-main-600 hover:border-main-800 hover:text-main-800 disabled:opacity-25'
                    onClick={handlePrevious}
                    disabled={!shouldShowPrevious()}
                  >
                    <FaArrowLeft />
                  </button>
                  <button
                    className='flex items-center gap-2 rounded-full border-2 border-main-600 p-2 text-main-600 hover:border-main-800 hover:text-main-800 disabled:opacity-25'
                    onClick={handleNext}
                    disabled={!shouldShowNext()}
                  >
                    <FaArrowRight />
                  </button>
                </div>
                <ProjectClock
                  projectId={projectDetails.id}
                  startingTime={projectDetails.timeSpent}
                />
              </div>
            </div>
            <div className='flex grow flex-col items-center sm:flex-initial'>
              <Counters />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDetails;
