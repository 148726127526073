type ButtonType = 'brand' | 'danger' | 'colorless';
type ButtonSize = 'sm' | 'md' | 'lg';

type ButtonProps = {
  type?: ButtonType;
  size?: ButtonSize;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  attributes?: Record<string, string | number>;
  children: React.ReactNode;
  extraClasses?: string;
};

function Button({
  children,
  type = 'brand',
  size = 'md',
  onClick,
  disabled = false,
  loading = false,
  attributes = {},
  extraClasses = '',
}: ButtonProps) {
  const classNamesForType: {
    [key in ButtonType]: string;
  } = {
    brand: 'bg-main-500 text-white hover:bg-main-600',
    danger: 'bg-red-500 text-white hover:bg-red-600',
    colorless: 'text-black hover:text-main-600',
  };

  const classNamesForSize: {
    [key in ButtonSize]: string;
  } = {
    sm: 'px-2 py-1 text-sm font-semibold',
    md: 'px-4 py-2 text-base font-semibold',
    lg: 'px-6 py-3 text-lg font-semibold',
  };

  return (
    <button
      className={`rounded-md disabled:animate-pulse disabled:opacity-75 ${classNamesForType[type]} ${classNamesForSize[size]} ${extraClasses}`}
      disabled={disabled || loading}
      onClick={onClick}
      {...attributes}
    >
      {children}
    </button>
  );
}

export default Button;
