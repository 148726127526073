import { useAuth0 } from '@auth0/auth0-react';
import axios from '../../utils/customAxiosClient';
import { useRef, useState } from 'react';
import { Template, TemplatePart } from '../../types/Template';
import toast from 'react-hot-toast';
import debounce from 'lodash/debounce';

export type TemplateSaveErrors = {
  emptyName: boolean;
  emptyPartName: boolean;
  partWithoutRows: boolean;
  rowWithoutValue: boolean;
};

export default function useTemplates() {
  const { getAccessTokenSilently } = useAuth0();
  const [saveErrors, setSaveErrors] = useState<TemplateSaveErrors>({
    emptyName: false,
    emptyPartName: false,
    partWithoutRows: false,
    rowWithoutValue: false,
  });
  const [templateCreationLoading, setTemplateCreationLoading] = useState(false);
  const [templatePartCreationLoading, setTemplatePartCreationLoading] =
    useState(false);
  const [templateDetails, setTemplateDetails] = useState<Template | null>(null);
  const [templates, setTemplates] = useState<Template[]>([] as Template[]);
  const [fetchTemplatesLoading, setFetchTemplatesLoading] = useState(false);

  const debouncedSave = useRef(
    debounce((template: Template) => {
      toast.promise(
        save(template),
        {
          error: 'Failed to save template',
          loading: 'Saving template...',
          success: 'Template saved!',
        },
        {
          position: 'top-right',
        }
      );
    }, 1000)
  );

  const save = async (template: Template) => {
    if (!template) return;

    await editTemplate(template.id, {
      name: template.name,
      level: template.level,
      parts: template.parts,
    });

    await Promise.all(
      template.parts.map((part) => editTemplatePart(template.id, part.id, part))
    );
  };

  const createTemplate = async (template: Template) => {
    try {
      setTemplateCreationLoading(true);
      const token = await getAccessTokenSilently();
      await axios.post(
        `${import.meta.env.VITE_APP_BACKEND_URL}/template`,
        template,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (e) {
      toast.error('Failed to create template');
    } finally {
      setTemplateCreationLoading(false);
    }
  };

  const getTemplateDetails = async (id: string) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${import.meta.env.VITE_APP_BACKEND_URL}/template/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTemplateDetails(response.data);
  };

  const fetchTemplates = async ({ userId }: { userId?: string }) => {
    setFetchTemplatesLoading(true);
    const token = await getAccessTokenSilently();
    const queryParams = new URLSearchParams();

    if (userId) {
      queryParams.append('userId', userId);
    }

    const response = await axios.get(
      `${
        import.meta.env.VITE_APP_BACKEND_URL
      }/template?${queryParams.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTemplates(response.data);
    setFetchTemplatesLoading(false);
  };

  const createTemplatePart = async (
    templateId: string,
    part: Partial<TemplatePart>
  ) => {
    try {
      setTemplatePartCreationLoading(true);
      const token = await getAccessTokenSilently();
      await axios.post(
        `${import.meta.env.VITE_APP_BACKEND_URL}/template/${templateId}/part`,
        part,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (e) {
      toast.error('Failed to create part');
    } finally {
      setTemplatePartCreationLoading(false);
    }
  };

  const editTemplate = async (
    id: string,
    payload: { name?: string; level?: string; parts?: TemplatePart[] }
  ) => {
    const token = await getAccessTokenSilently();
    await axios.patch(
      `${import.meta.env.VITE_APP_BACKEND_URL}/template/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const deleteTemplate = async (id: string) => {
    const token = await getAccessTokenSilently();
    await axios.delete(
      `${import.meta.env.VITE_APP_BACKEND_URL}/template/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const editTemplatePart = async (
    templateId: string,
    partId: string,
    payload: Partial<TemplatePart>
  ) => {
    const token = await getAccessTokenSilently();
    await axios.patch(
      `${
        import.meta.env.VITE_APP_BACKEND_URL
      }/template/${templateId}/part/${partId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const deleteTemplatePart = async (templateId: string, partId: string) => {
    const token = await getAccessTokenSilently();
    await axios.delete(
      `${
        import.meta.env.VITE_APP_BACKEND_URL
      }/template/${templateId}/part/${partId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const handleSetTemplateDetails = (data: Template) => {
    setSaveErrors({
      emptyName: false,
      emptyPartName: false,
      partWithoutRows: false,
      rowWithoutValue: false,
    });

    setTemplateDetails(data);
    const anyError = validateNewTemplate(data);
    if (anyError) return;
    debouncedSave.current(data);
  };

  const validateNewTemplate = (data: Template): boolean => {
    const newErrors = {
      emptyName: false,
      emptyPartName: false,
      partWithoutRows: false,
      rowWithoutValue: false,
    };

    if (!data.name) {
      newErrors.emptyName = true;
    }
    if (data.parts.some((part) => !part.name)) {
      newErrors.emptyPartName = true;
    }
    if (data.parts.some((part) => part.rows.length === 0)) {
      newErrors.partWithoutRows = true;
    }
    if (data.parts.some((part) => part.rows.some((row) => !row))) {
      newErrors.rowWithoutValue = true;
    }

    setSaveErrors(newErrors);

    return Object.values(newErrors).some((error) => error);
  };

  return {
    templates,
    templateDetails,
    saveErrors,
    templateCreationLoading,
    templatePartCreationLoading,
    fetchTemplatesLoading,
    setTemplateDetails: handleSetTemplateDetails,
    createTemplate,
    getTemplateDetails,
    fetchTemplates,
    createTemplatePart,
    deleteTemplate,
    deleteTemplatePart,
  };
}
