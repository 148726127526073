import useProtectedPage from '../../hooks/utils/useProtectedPage';
import { useEffect } from 'react';
import useUser from '../../hooks/data/useUser';
import { Link } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import ProjectCard from './components/ProjectCard/ProjectCard';
import TemplateCard from './components/TemplateCard/TemplateCard';
import LoadingFill from '../../components/core/LoadingFIll/LoadingFill';
import { secondsToDashboardTime } from '../../hooks/utils/dateTimeUtils';
import useProjects from '../../hooks/data/useProjects';
import useTemplates from '../../hooks/data/useTemplates';

function Dashboard() {
  useProtectedPage();

  const { myUser, fetchMyUser, loading } = useUser();
  const { projects, fetchProjectsLoading, setProjectsFilter, projectsFilter } =
    useProjects();
  const { templates, fetchTemplates, fetchTemplatesLoading } = useTemplates();

  useEffect(() => {
    fetchMyUser();
  }, []);

  useEffect(() => {
    if (!myUser) return;
    setProjectsFilter(prev => ({
      ...prev,
      userId: myUser.id,
      fetchAfter: true,
    }));
    fetchTemplates({ userId: myUser.id });
  }, [myUser]);

  if (loading || fetchProjectsLoading || fetchTemplatesLoading)
    return (
      <div className='fixed left-0 top-0 flex h-screen w-screen items-center justify-center'>
        <LoadingFill />
      </div>
    );

  return (
    <div className='flex flex-1 justify-center'>
      {myUser && (
        <div className='mt-6 flex w-full max-w-screen-lg flex-col items-center sm:mt-14 sm:items-start'>
          <section className='flex w-full flex-col px-8'>
            <div className='flex items-center justify-between gap-2'>
              <h1 className='mb-3 text-3xl font-bold underline decoration-main-400'>
                My Projects
              </h1>
              <label className='flex items-center gap-2'>
                Hide done
                <input
                  type='checkbox'
                  checked={projectsFilter.hideDone}
                  onChange={() => {
                    setProjectsFilter(prev => ({
                      ...prev,
                      hideDone: !prev.hideDone,
                      fetchAfter: true,
                    }));
                  }}
                  className='size-5 cursor-pointer rounded-md border-2 border-main-600 p-1 text-main-600'
                />
              </label>
            </div>

            <p className='mb-2 text-xs font-semibold'>
              Total time spent{' '}
              {myUser.projects &&
                secondsToDashboardTime(
                  myUser.projects.reduce((acc, p) => acc + p.timeSpent, 0)
                )}
            </p>
            <ul className='flex flex-col gap-2 sm:flex-row sm:flex-wrap'>
              {projects.map(project => (
                <li key={project.id}>
                  <Link to={`/projects/${project.id}`}>
                    <ProjectCard project={project} />
                  </Link>
                </li>
              ))}
            </ul>
          </section>
          <section className='mt-8 flex w-full flex-col px-8 pb-12'>
            <div className='mb-3 mt-5 flex items-center gap-2'>
              <h1 className='text-3xl font-bold underline decoration-main-400'>
                My Templates
              </h1>
              <Link
                className='text-main-600 hover:text-main-800'
                to='/templates/create'
              >
                <IoIosAddCircleOutline size={25} />
              </Link>
            </div>
            <ul className='flex flex-col gap-2 sm:flex-row sm:flex-wrap'>
              {templates.map(template => (
                <li key={template.id}>
                  <Link to={`/templates/admin/${template.id}`}>
                    <TemplateCard template={template} />
                  </Link>
                </li>
              ))}
            </ul>
          </section>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
