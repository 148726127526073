import { useAuth0 } from '@auth0/auth0-react';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition,
} from '@headlessui/react';
import { AiOutlineLogout } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import logo from '../../../assets/crochet-buddy.svg';

function NavBar() {
  const { logout, isAuthenticated, isLoading, user } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  if (isLoading || (!isLoading && !isAuthenticated)) return null;

  return (
    <nav className='flex flex-row items-center justify-between px-5 py-2'>
      <Link className='text-lg font-bold sm:text-xl' to='/dashboard'>
        <img src={logo} className='h-8' />
      </Link>
      <Menu>
        <MenuButton>
          <div className='mr-2 flex w-full items-center justify-center gap-3 py-2'>
            <p className='font-semibold'>
              {user?.given_name || user?.name || user?.given_name}
            </p>
            <img
              src={user?.picture}
              alt={user?.name}
              className='size-6 rounded-full sm:size-8'
            />
          </div>
        </MenuButton>
        <Transition
          enter='transition ease-out duration-75'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <MenuItems
            anchor='bottom'
            className='flex flex-col divide-y divide-main-300 rounded-md border bg-main-200 shadow-sm'
          >
            <div>
              <MenuItem>
                <button
                  onClick={handleLogout}
                  className='flex cursor-pointer items-center gap-3 rounded-b-md bg-main-200 p-2 px-6 font-semibold text-gray-800 hover:bg-main-500 hover:text-white data-[focus]:bg-main-500 data-[focus]:text-white'
                >
                  Logout <AiOutlineLogout />
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </nav>
  );
}

export default NavBar;
