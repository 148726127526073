import { useRef, useState } from 'react';
import useTemplates from '../../../../hooks/data/useTemplates.ts';
import { TemplatePart } from '../../../../types/Template.ts';
import { FaCopy, FaEllipsisV, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { IoIosAddCircleOutline } from 'react-icons/io';
import toast from 'react-hot-toast';
import Button from '../../../../components/shared/Button/Button.tsx';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from '@headlessui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';

type AddPartModalProps = {
  isOpen: boolean;
  success: () => void;
  onClose: () => void;
  templateId: string;
};

function AddPartModal({
  isOpen,
  success,
  onClose,
  templateId,
}: AddPartModalProps) {
  const [name, setName] = useState('');
  const [rows, setRows] = useState<string[]>([]);

  const menuRef = useRef<HTMLDivElement>(null);

  const { createTemplatePart, templatePartCreationLoading } = useTemplates();

  const resetModal = () => {
    setName('');
    setRows([]);
  };

  const handleAddNewPart = async () => {
    const newPart: Partial<TemplatePart> = {
      id: crypto.randomUUID(),
      name: name,
      rows,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    if (!newPart.name) {
      return toast.error('Name is required');
    }

    if (!newPart.rows || newPart.rows.length === 0) {
      return toast.error('At least one row is required');
    }

    if (newPart.rows.some(row => !row)) {
      return toast.error('All rows must have a value');
    }

    await toast.promise(createTemplatePart(templateId, newPart), {
      loading: 'Adding part...',
      success: 'Part added!',
      error: 'Failed to add part',
    });

    resetModal();
    success();
  };

  const handleAddRow = () => {
    setRows([...rows, '']);
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  const handleDuplicateRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 0, rows[index]);
    setRows(newRows);
  };

  const handleRemoveRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newRows = [...rows];
    const [removed] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, removed);

    setRows(newRows);
  };

  if (!isOpen) return null;

  return (
    <Transition appear show={isOpen}>
      <div className='fixed right-0 top-0 flex h-screen w-screen items-center justify-center bg-black/75'>
        <Dialog
          onClose={handleClose}
          as='div'
          className='relative focus:outline-none'
        >
          <div className='fixed inset-0 w-screen overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4'>
              <TransitionChild
                enter='ease-out duration-300'
                enterFrom='opacity-0 transform-[scale(95%)]'
                enterTo='opacity-100 transform-[scale(100%)]'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 transform-[scale(100%)]'
                leaveTo='opacity-0 transform-[scale(95%)]'
              >
                <DialogPanel className='w-full max-w-md rounded-xl bg-white p-6'>
                  <DialogTitle
                    as='h3'
                    className='mb-2 text-2xl font-medium text-black'
                  >
                    Add a new part
                  </DialogTitle>
                  <form className='flex flex-col gap-4'>
                    <label className='flex flex-col'>
                      Name
                      <input
                        type='text'
                        className='rounded-md border border-main-400'
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </label>
                    <div className='flex items-center gap-2'>
                      <h2 className='text-xl font-semibold'>Rows</h2>
                      <button
                        type='button'
                        className='text-main-600 hover:text-main-800'
                        onClick={handleAddRow}
                      >
                        <IoIosAddCircleOutline size={25} />
                      </button>
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId='droppable'>
                        {provided => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className='custom-scroll-bar flex flex-col gap-3 overflow-y-auto pr-2'
                          >
                            {rows.map((row, rowIndex) => (
                              <Draggable
                                key={rowIndex}
                                draggableId={rowIndex.toString()}
                                index={rowIndex}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <label
                                      key={rowIndex}
                                      className={
                                        'flex items-center gap-3 ' +
                                        (snapshot.isDragging
                                          ? 'bg-main-100'
                                          : '')
                                      }
                                    >
                                      {rowIndex + 1}:
                                      <input
                                        type='text'
                                        value={row}
                                        className='w-full rounded-md border border-main-400'
                                        onChange={e => {
                                          const newRows = [...rows];
                                          newRows[rowIndex] = e.target.value;
                                          setRows(newRows);
                                        }}
                                      />
                                      <Menu>
                                        <MenuButton>
                                          <FaEllipsisV />
                                        </MenuButton>
                                        <Transition
                                          enter='transition ease-out duration-75'
                                          enterFrom='opacity-0 scale-95'
                                          enterTo='opacity-100 scale-100'
                                          leave='transition ease-in duration-100'
                                          leaveFrom='opacity-100 scale-100'
                                          leaveTo='opacity-0 scale-95'
                                        >
                                          <MenuItems
                                            anchor='bottom end'
                                            ref={menuRef}
                                            className='flex flex-col items-stretch rounded-md bg-white shadow-sm'
                                          >
                                            <MenuItem>
                                              <button
                                                onClick={() =>
                                                  handleDuplicateRow(rowIndex)
                                                }
                                                className='flex items-center justify-center gap-2 rounded-t-md bg-main-100 p-2 hover:bg-main-500 hover:text-white'
                                              >
                                                Duplicate <FaCopy />
                                              </button>
                                            </MenuItem>
                                            <MenuItem>
                                              <button
                                                onClick={() =>
                                                  handleRemoveRow(rowIndex)
                                                }
                                                className='flex items-center justify-center gap-2 rounded-b-md bg-main-100 p-2 hover:bg-red-500 hover:text-white'
                                              >
                                                Delete
                                                <FaRegTrashAlt />
                                              </button>
                                            </MenuItem>
                                          </MenuItems>
                                        </Transition>
                                      </Menu>
                                    </label>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {rows.length > 0 && (
                      <Button
                        attributes={{
                          type: 'button',
                        }}
                        type='colorless'
                        onClick={handleAddRow}
                        extraClasses='flex flex-gap items-center self-center gap-2'
                      >
                        Add row
                        <FaPlus />
                      </Button>
                    )}
                    <Button
                      loading={templatePartCreationLoading}
                      onClick={handleAddNewPart}
                      attributes={{
                        type: 'button',
                      }}
                    >
                      Add part
                    </Button>
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </div>
    </Transition>
  );
}

export default AddPartModal;
