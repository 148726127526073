import { ChangeEvent, useState } from 'react';
import { FaMinus, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import Button from '../../../../components/shared/Button/Button';

type Counter = {
  name: string;
  value: number;
};

function Counters() {
  const [counters, setCounters] = useState<Counter[]>([]);

  const handleAddCounter = () => {
    setCounters([
      ...counters,
      {
        name: 'New Counter (Edit me!)',
        value: 0,
      },
    ]);
  };

  const handleIncreaseCounter = (index: number) => {
    const newCounters = [...counters];
    newCounters[index] = {
      ...counters[index],
      value: counters[index].value + 1,
    };
    setCounters(newCounters);
  };

  const handleDecreaseCounter = (index: number) => {
    const newCounters = [...counters];
    newCounters[index] = {
      ...counters[index],
      value: counters[index].value - 1,
    };
    setCounters(newCounters);
  };

  const handleDeleteCounter = (index: number) => {
    const newCounters = [...counters];
    newCounters.splice(index, 1);
    setCounters(newCounters);
  };

  const handleCounterChangeName = (index: number, e: ChangeEvent) => {
    const newCounters = [...counters];
    newCounters[index] = {
      ...counters[index],
      name: (e.target as HTMLInputElement).value,
    };
    setCounters(newCounters);
  };

  return (
    <>
      <Button
        type='colorless'
        onClick={handleAddCounter}
        extraClasses='flex items-center gap-2'
      >
        New counter
        <FaPlus size={12} />
      </Button>
      <div className='custom-scroll-bar flex max-h-[30vh] w-full flex-col gap-4 overflow-auto pb-4 pr-2 md:max-h-[50vh]'>
        {counters.map((counter, index) => (
          <div
            key={index}
            className='flex w-full flex-col rounded-lg border border-main-200 pt-1 shadow-md'
          >
            <div className='flex w-full items-center justify-between px-2'>
              <input
                className='border-0 bg-transparent'
                type='text'
                value={counter.name}
                onChange={e => handleCounterChangeName(index, e)}
              />
              <button
                className='flex items-center gap-2 rounded-full p-2 font-semibold text-main-800 hover:text-red-500'
                onClick={() => handleDeleteCounter(index)}
              >
                <FaRegTrashAlt size={18} />
              </button>
            </div>
            <div className='flex w-full items-center justify-center gap-8 pb-5'>
              <button
                className='ml-1 rounded-full bg-main-600 p-2 text-xs text-white hover:bg-main-800'
                onClick={() => handleDecreaseCounter(index)}
              >
                <FaMinus size={12} />
              </button>
              <p className='text-4xl'>{counter.value}</p>
              <button
                className='mr-1 rounded-full bg-main-600 p-2 text-xs text-white hover:bg-main-800'
                onClick={() => handleIncreaseCounter(index)}
              >
                <FaPlus size={12} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Counters;
