import { useAuth0 } from '@auth0/auth0-react';
import axios from '../../utils/customAxiosClient';
import { Project } from '../../types/Project';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import debounce from 'lodash/debounce';

type ProjectsFilter = {
  hideDone: boolean;
  userId: string | null;
  fetchAfter: boolean;
};

export default function useProjects() {
  const { getAccessTokenSilently } = useAuth0();

  const [projectDetails, setProjectDetails] = useState<Project | null>(null);
  const [projects, setProjects] = useState<Project[]>([] as Project[]);
  const [projectCreationLoading, setProjectCreationLoading] = useState(false);
  const [fetchProjectsLoading, setFetchProjectsLoading] = useState(false);

  const [projectsFilter, setProjectsFilter] = useState<ProjectsFilter>({
    hideDone: true,
    userId: null,
    fetchAfter: false,
  });

  const debouncedSave = useRef(
    debounce((project: Project) => {
      toast.promise(
        updateProject(project.id, {
          name: project.name,
          currentPart: project.currentPart,
          currentRow: project.currentRow,
          done: project.done,
        }),
        {
          error: 'Failed to save project',
          loading: 'Saving project...',
          success: 'Project saved!',
        }
      );
    }, 1000)
  );

  const createProject = async (project: {
    templateId: string;
    name: string;
    id: string;
  }) => {
    try {
      setProjectCreationLoading(true);
      const token = await getAccessTokenSilently();
      await axios.post(
        `${import.meta.env.VITE_APP_BACKEND_URL}/project`,
        project,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (e) {
      toast.error('Failed to create project');
    } finally {
      setProjectCreationLoading(false);
    }
  };

  const fetchProject = async (id: string) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${import.meta.env.VITE_APP_BACKEND_URL}/project/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProjectDetails(response.data);
  };

  const fetchProjects = async () => {
    setFetchProjectsLoading(true);
    const token = await getAccessTokenSilently();
    const queryParams = new URLSearchParams();
    if (projectsFilter.userId) {
      queryParams.append('userId', projectsFilter.userId);
    }
    if (projectsFilter.hideDone) {
      queryParams.append('filter[done]', 'false');
    }
    const response = await axios.get(
      `${
        import.meta.env.VITE_APP_BACKEND_URL
      }/project?${queryParams.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProjects(response.data);
    setFetchProjectsLoading(false);
  };

  useEffect(() => {
    if (projectsFilter.fetchAfter) {
      fetchProjects();
      setProjectsFilter({ ...projectsFilter, fetchAfter: false });
    }
  }, [projectsFilter]);

  const updateProject = async (id: string, project: Partial<Project>) => {
    const token = await getAccessTokenSilently();
    await axios.patch(
      `${import.meta.env.VITE_APP_BACKEND_URL}/project/${id}`,
      project,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const deleteProject = async (id: string) => {
    const token = await getAccessTokenSilently();
    await axios.delete(
      `${import.meta.env.VITE_APP_BACKEND_URL}/project/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return {
    projects,
    projectDetails,
    projectCreationLoading,
    fetchProjectsLoading,
    projectsFilter,
    setProjectsFilter,
    setProjectDetails: (project: Project) => {
      setProjectDetails(project);
      debouncedSave.current(project);
    },
    fetchProjects,
    createProject,
    fetchProject,
    updateProject,
    deleteProject,
  };
}
