import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTemplates from '../../hooks/data/useTemplates';
import AddPartModal from './components/AddPartModal/AddPartModal';
import useProjects from '../../hooks/data/useProjects';
import { FaRegTrashAlt, FaEdit, FaEllipsisH, FaPlus } from 'react-icons/fa';
import { Template, TemplatePart } from '../../types/Template';
import EditPartModal from './components/EditPartModal/EditPartModal';
import { IoIosAddCircleOutline } from 'react-icons/io';
import toast from 'react-hot-toast';
import TemplateSaveErrorBanner from './components/TemplateSaveErrorBanner/TemplateSaveErrorBanner';
import useProtectedPage from '../../hooks/utils/useProtectedPage';
import {
  Menu,
  MenuButton,
  Transition,
  MenuItems,
  MenuItem,
} from '@headlessui/react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import LoadingFill from '../../components/core/LoadingFIll/LoadingFill';
import Button from '../../components/shared/Button/Button';
import DeleteConfirmationModal from '../../components/shared/DeleteConfirmationModal/DeleteConfirmationModal';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import CreateProjectModal from './components/CreateProjectModal/CreateProjectModal';

function TemplateAdmin() {
  useProtectedPage();

  const { id } = useParams();
  const {
    saveErrors,
    templateDetails,
    getTemplateDetails,
    deleteTemplate,
    setTemplateDetails,
    deleteTemplatePart,
  } = useTemplates();
  const { createProject, projectCreationLoading } = useProjects();
  const [addPartModalOpen, setAddPartModalOpen] = useState(false);
  const [editPartModalOpen, setEditPartModalOpen] = useState(false);
  const [editPartId, setEditPartId] = useState<string | null>(null);
  const [isDeletePartModalOpen, setIsDeletePartModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    getTemplateDetails(id);
  }, [id]);

  const onAddPartSuccess = async () => {
    if (!id) return;
    setAddPartModalOpen(false);
    await getTemplateDetails(id);
  };

  const onEditPartSuccess = async () => {
    if (!id) return;
    setEditPartModalOpen(false);
  };

  const handleEditPart = async (part: TemplatePart) => {
    setEditPartId(part.id);
    setEditPartModalOpen(true);
  };

  const handleCreateProjectFromTemplate = async (projectName: string) => {
    if (!id || !templateDetails) return;

    if (templateDetails.parts.length === 0) {
      return toast.error('Template must have at least one part');
    }

    await toast.promise(
      createProject({
        id: crypto.randomUUID(),
        name: projectName,
        templateId: id,
      }),
      {
        loading: 'Creating project...',
        success: 'Project created!',
        error: 'Failed to create project',
      }
    );

    navigate('/dashboard');
  };

  const handleDeleteTemplate = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteTemplateConfirmation = async () => {
    if (!id) return;
    await toast.promise(deleteTemplate(id), {
      loading: 'Deleting template...',
      success: 'Template deleted!',
      error: 'Failed to delete template',
    });
    setIsDeleteModalOpen(false);
    navigate('/dashboard');
  };

  const handleChangeName = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTemplateDetails({
      ...templateDetails,
      name: event.target.value,
    } as Template);
  };

  const handleLevelChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!id) return;
    setTemplateDetails({
      ...templateDetails,
      level: event.target.value,
    } as Template);
  };

  const handleDeletePart = () => {
    setIsDeletePartModalOpen(true);
  };

  const handleDeletePartConfirmation = async () => {
    if (!templateDetails) return;
    if (!id) return;

    const partId = templateDetails.parts[0].id;

    await toast.promise(deleteTemplatePart(id, partId), {
      loading: 'Deleting part...',
      success: 'Part deleted!',
      error: 'Failed to delete part',
    });
    setIsDeletePartModalOpen(false);
    await getTemplateDetails(id);
  };

  if (!id || !templateDetails) {
    return (
      <div className='fixed left-0 top-0 flex h-screen w-screen items-center justify-center'>
        <LoadingFill />
      </div>
    );
  }

  const handleClickOutsideAddPartModal = () => {
    setAddPartModalOpen(false);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const parts = Array.from(templateDetails.parts);
    const [reorderedPart] = parts.splice(result.source.index, 1);
    parts.splice(result.destination.index, 0, reorderedPart);

    setTemplateDetails({
      ...templateDetails,
      parts,
    });
  };

  return (
    <>
      <DeleteConfirmationModal
        description='Are you sure you want to delete this template?'
        details='This action cannot be undone.'
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirmation={handleDeleteTemplateConfirmation}
        title='Delete template'
      />
      <DeleteConfirmationModal
        description='Are you sure you want to delete this part?'
        details='This action cannot be undone.'
        isOpen={isDeletePartModalOpen}
        onClose={() => setIsDeletePartModalOpen(false)}
        onConfirmation={handleDeletePartConfirmation}
        title='Delete part'
      />
      <AddPartModal
        isOpen={addPartModalOpen}
        success={onAddPartSuccess}
        onClose={handleClickOutsideAddPartModal}
        templateId={id}
      />
      <CreateProjectModal
        initialProjectName={templateDetails.name}
        isOpen={createProjectModalOpen}
        handleClose={() => setCreateProjectModalOpen(false)}
        handleConfirm={handleCreateProjectFromTemplate}
      />
      {editPartId && (
        <EditPartModal
          isOpen={editPartModalOpen}
          success={onEditPartSuccess}
          partId={editPartId}
          template={templateDetails}
          setTemplate={setTemplateDetails}
          saveErrors={saveErrors}
        />
      )}
      <div className='flex w-full flex-1 flex-col items-center justify-between'>
        <div className='flex w-full max-w-screen-lg flex-1 flex-col gap-3 divide-y divide-main-400 p-4'>
          <div className='mb-4 flex flex-col gap-2'>
            <TemplateSaveErrorBanner saveErrors={saveErrors} />
            <div className='flex w-full items-center justify-between'>
              <h2 className='text-2xl font-bold'>Template info</h2>
              <Menu>
                <MenuButton>
                  <FaEllipsisH size={20} />
                </MenuButton>
                <Transition
                  enter='transition ease-out duration-75'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <MenuItems
                    anchor='bottom end'
                    className='flex flex-col divide-y divide-main-300 rounded-md border bg-main-200 shadow-sm'
                  >
                    <MenuItem>
                      <button
                        disabled={
                          templateDetails.parts.length === 0 ||
                          projectCreationLoading
                        }
                        onClick={() => setCreateProjectModalOpen(true)}
                        className='group flex w-full items-center gap-2 rounded-t-lg px-3 py-1.5 hover:bg-main-600 hover:text-white disabled:animate-pulse disabled:opacity-75'
                      >
                        <BiPencil />
                        Create project
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        className='group flex w-full items-center gap-2 rounded-b-lg px-3 py-1.5 hover:bg-red-600 hover:text-white'
                        onClick={handleDeleteTemplate}
                      >
                        <BiTrash />
                        Delete template
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>

            <label className='flex flex-col'>
              Name:{' '}
              <input
                className='rounded-md border border-main-400'
                type='text'
                value={templateDetails.name}
                onChange={handleChangeName}
              />
            </label>
            <label className='flex flex-col'>
              Level:{' '}
              <select
                value={templateDetails.level}
                onChange={handleLevelChange}
                className='rounded-md border border-main-400'
              >
                <option value='BEGINNER'>BEGINNER</option>
                <option value='INTERMEDIATE'>INTERMEDIATE</option>
                <option value='ADVANCED'>ADVANCED</option>
              </select>
            </label>
          </div>
          <div className='flex flex-col gap-2 pt-4'>
            <div className='flex items-center gap-2'>
              <h2 className='text-2xl font-bold'>Parts</h2>
              <button
                className='text-main-600 hover:text-main-800'
                onClick={() => setAddPartModalOpen(true)}
              >
                <IoIosAddCircleOutline size={25} />
              </button>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {templateDetails.parts.map((part, index) => (
                      <Draggable
                        key={part.id}
                        draggableId={part.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              key={part.id}
                              className={
                                'rounded-md p-2 hover:bg-main-200/50 ' +
                                (snapshot.isDragging ? 'bg-main-200' : '')
                              }
                            >
                              <div className='flex items-center justify-between gap-10'>
                                <p className='text-lg font-semibold'>
                                  {part.name}
                                </p>
                                <div className='flex items-center gap-3'>
                                  <button
                                    className='hover:text-red-600'
                                    onClick={handleDeletePart}
                                  >
                                    <FaRegTrashAlt />
                                  </button>
                                  <button
                                    className='hover:text-main-700'
                                    onClick={() => handleEditPart(part)}
                                  >
                                    <FaEdit />
                                  </button>
                                </div>
                              </div>
                              {part.rows.map((row, index) => (
                                <p key={index}>
                                  {index + 1}: {row}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        {templateDetails.parts.length > 0 && (
          <Button
            attributes={{
              type: 'button',
            }}
            type='colorless'
            onClick={() => setAddPartModalOpen(true)}
            extraClasses='flex flex-gap items-center self-center gap-2'
          >
            Add part
            <FaPlus />
          </Button>
        )}
      </div>
    </>
  );
}

export default TemplateAdmin;
