export const secondsToStopwatch = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const hoursStr = hours < 10 ? `0${hours}` : hours;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

export const secondsToProjectCardTime = (time: number): string => {
  const days = Math.floor(time / 86400);
  const hours = Math.floor((time % 86400) / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  const daysStr = days ? (days < 10 ? `0${days}d` : `${days}d`) : '';
  const hoursStr = hours ? (hours < 10 ? `0${hours}h` : `${hours}h`) : '';
  const minutesStr = minutes
    ? minutes < 10
      ? `0${minutes}m`
      : `${minutes}m`
    : '';

  return `${daysStr} ${hoursStr} ${minutesStr}`.trim();
};

export const secondsToDashboardTime = (time: number): string => {
  const weeks = Math.floor(time / 604800);
  const days = Math.floor((time % 604800) / 86400);
  const hours = Math.floor((time % 86400) / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  const weeksStr = weeks ? (weeks < 10 ? `0${weeks}w` : `${weeks}w`) : '';
  const daysStr = days ? (days < 10 ? `0${days}d` : `${days}d`) : '';
  const hoursStr = hours ? (hours < 10 ? `0${hours}h` : `${hours}h`) : '';
  const minutesStr = minutes
    ? minutes < 10
      ? `0${minutes}m`
      : `${minutes}m`
    : '';

  return `${weeksStr} ${daysStr} ${hoursStr} ${minutesStr}`.trim();
};
