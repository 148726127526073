import { useAuth0 } from '@auth0/auth0-react';
import axios from '../../utils/customAxiosClient';
import { useState } from 'react';
import { User } from '../../types/User';

export default function useUser() {
  const { getAccessTokenSilently } = useAuth0();

  const [myUser, setMyUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchMyUser = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(
      `${import.meta.env.VITE_APP_BACKEND_URL}/user/self`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMyUser(data);
    setLoading(false);
  };

  return { myUser, fetchMyUser, loading };
}
