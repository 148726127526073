import { Template } from '../../../../types/Template';

type TemplateCardProps = {
  template: Template;
};

function TemplateCard({ template }: TemplateCardProps) {
  return (
    <div className='w-full flex-1 rounded-md border border-main-100 p-3 shadow-md hover:bg-main-200 sm:w-60'>
      <p className='truncate pr-2 font-semibold'>{template.name}</p>
      <div className='h-8'>
        <p className='text-sm text-main-600'>{template.parts.length} parts </p>
      </div>
    </div>
  );
}

export default TemplateCard;
