import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../components/shared/Button/Button';

function CreateProjectModal({
  initialProjectName,
  isOpen,
  handleClose,
  handleConfirm,
}: {
  initialProjectName: string;
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (name: string) => void;
}) {
  const [projectName, setProjectName] = useState(
    'My Project - ' + initialProjectName
  );

  const onHandleConfirm = () => {
    if (!projectName) return toast.error('Project name is required');
    handleConfirm(projectName);
    handleClose();
  };

  return (
    <Transition appear show={isOpen}>
      <div className='fixed right-0 top-0 flex h-screen w-screen items-center justify-center bg-black/75'>
        <Dialog
          onClose={handleClose}
          as='div'
          className='relative focus:outline-none'
        >
          <div className='fixed inset-0 w-screen overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4'>
              <TransitionChild
                enter='ease-out duration-300'
                enterFrom='opacity-0 transform-[scale(95%)]'
                enterTo='opacity-100 transform-[scale(100%)]'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 transform-[scale(100%)]'
                leaveTo='opacity-0 transform-[scale(95%)]'
              >
                <DialogPanel className='w-full max-w-md rounded-xl bg-white p-6'>
                  <DialogTitle
                    as='h3'
                    className='mb-2 text-2xl font-medium text-black'
                  >
                    Create a new project from template "{initialProjectName}"
                  </DialogTitle>
                  <label className='mb-4 flex flex-col gap-1'>
                    Project name
                    <input
                      type='text'
                      className='rounded-md border border-main-400'
                      value={projectName}
                      onChange={e => setProjectName(e.target.value)}
                    />
                  </label>
                  <Button onClick={onHandleConfirm}>Create</Button>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </div>
    </Transition>
  );
}

export default CreateProjectModal;
