import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

console.log(import.meta.env);

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  release: APP_VERSION,
  integrations: [],
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-rvoux6ortu6t2ohz.us.auth0.com"
      clientId="qhiC6LaNe6AHMOwHOJ1uOs1oHwwBwFYr"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'http://api.stitchtracker.com',
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);
