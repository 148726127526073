import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';

function PartCompletedPopUp({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (newStatus: boolean) => void;
}) {
  return (
    <Transition appear show={isOpen}>
      <Dialog
        as='div'
        className='relative z-10 focus:outline-none'
        onClose={() => setIsOpen(false)}
      >
        <div className='fixed inset-0 z-10 w-screen overflow-y-auto bg-black/75'>
          <div className='flex min-h-full items-center justify-center p-4'>
            <TransitionChild
              enter='ease-out duration-300'
              enterFrom='opacity-0 transform-[scale(95%)]'
              enterTo='opacity-100 transform-[scale(100%)]'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 transform-[scale(100%)]'
              leaveTo='opacity-0 transform-[scale(95%)]'
            >
              <DialogPanel className='w-full max-w-md rounded-xl bg-white p-6'>
                <DialogTitle as='h3' className='text-base/7 font-medium'>
                  Part completed 🥳
                </DialogTitle>
                <p className='mt-2 text-sm/6'>
                  🧶 You rock! Keep up the good work!
                </p>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PartCompletedPopUp;
