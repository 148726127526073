export enum TemplateLevel {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}

export type TemplatePart = {
  id: string;
  name: string;
  rows: string[];
  createdAt: Date;
  updatedAt: Date;
};

export type Template = {
  id: string;
  userId: string;
  name: string;
  level: TemplateLevel;
  parts: TemplatePart[];
  createdAt: Date;
  updatedAt: Date;
};
