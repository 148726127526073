import useTemplates from '../../hooks/data/useTemplates';
import { Template, TemplateLevel } from '../../types/Template';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../components/shared/Button/Button';
import useProtectedPage from '../../hooks/utils/useProtectedPage';

function CreateTemplate() {
  useProtectedPage();

  const { createTemplate, templateCreationLoading } = useTemplates();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [form, setForm] = useState<{
    name: string;
    level: TemplateLevel;
  }>({
    name: '',
    level: TemplateLevel.BEGINNER,
  });

  const handleSubmit = async (values: {
    name: string;
    level: TemplateLevel;
  }) => {
    if (!validateForm()) return;

    const newTemplate: Template = {
      id: crypto.randomUUID(),
      createdAt: new Date(),
      updatedAt: new Date(),
      userId: user?.sub as string,
      level: values.level,
      name: values.name,
      parts: [],
    };

    await toast.promise(createTemplate(newTemplate), {
      error: 'Failed to create template',
      loading: 'Creating template...',
      success: 'Template created successfully',
    });

    navigate('/dashboard');
  };

  const validateForm = () => {
    if (!form.name.trim()) {
      toast.error('Name is required');
      return false;
    }

    return true;
  };

  return (
    <>
      <div className='flex w-full flex-1 flex-col items-center'>
        <h1 className='mt-10 text-2xl font-bold underline decoration-main-400 underline-offset-2 sm:text-3xl'>
          Create new template
        </h1>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(form);
          }}
          className='mt-4 flex w-5/6 flex-col items-stretch gap-4 sm:w-1/3'
        >
          <label className='flex flex-col'>
            Name:
            <input
              type='text'
              value={form.name}
              onChange={e => setForm({ ...form, name: e.target.value })}
              className='rounded-md border border-main-400'
            />
          </label>
          <label className='flex flex-col'>
            Level:
            <select
              value={form.level}
              onChange={e =>
                setForm({ ...form, level: e.target.value as TemplateLevel })
              }
              className='rounded-md border border-main-400'
            >
              <option value={TemplateLevel.BEGINNER}>Beginner</option>
              <option value={TemplateLevel.INTERMEDIATE}>Intermediate</option>
              <option value={TemplateLevel.ADVANCED}>Advanced</option>
            </select>
          </label>
          <Button
            loading={templateCreationLoading}
            attributes={{ type: 'submit' }}
            extraClasses='mt-8'
          >
            Create
          </Button>
        </form>
      </div>
    </>
  );
}

export default CreateTemplate;
