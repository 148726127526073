import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  Transition,
  Description,
} from '@headlessui/react';
import Button from '../Button/Button';

function DeleteConfirmationModal({
  isOpen,
  onClose,
  onConfirmation,
  description,
  details,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => void;
  title: string;
  description: string;
  details: string;
}) {
  return (
    <Transition appear show={isOpen}>
      <div className='fixed right-0 top-0 flex h-screen w-screen items-center justify-center bg-black/75'>
        <Dialog
          onClose={onClose}
          as='div'
          className='relative z-10 focus:outline-none'
        >
          <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4'>
              <TransitionChild
                enter='ease-out duration-300'
                enterFrom='opacity-0 transform-[scale(95%)]'
                enterTo='opacity-100 transform-[scale(100%)]'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 transform-[scale(100%)]'
                leaveTo='opacity-0 transform-[scale(95%)]'
              >
                <DialogPanel className='w-full max-w-md rounded-xl bg-white p-6'>
                  <DialogTitle
                    as='h3'
                    className='text-xl font-medium text-black'
                  >
                    {title}
                  </DialogTitle>
                  <Description className='mt-2 text-black'>
                    {description}
                  </Description>
                  <p className='mt-2 text-sm/6 text-black'>{details}</p>
                  <div className='flex justify-end'>
                    <Button
                      type='danger'
                      onClick={onConfirmation}
                      extraClasses='mt-8'
                    >
                      Confirm
                    </Button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </div>
    </Transition>
  );
}

export default DeleteConfirmationModal;
